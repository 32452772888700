@import url('https://fonts.googleapis.com/css?family=Libre+Franklin:100,300,400,500,600,700,800,900');
// IMPORT ADDONS
@import 'addons/bootstrap/bootstrap';
@import 'addons/font-awesome/font-awesome';
@import 'addons/layerslider/layerslider';
@import 'addons/booklet/booklet';
@import 'addons/magnific-popup/magnific-popup';
@import 'addons/owl-carousel2/owl.carousel';
// IMPORT TEMPLATE
@import 'functions';

/*****************************************************/

// FONTS
$font-1: "Arial";
$font-2: "Libre Franklin";
//COLOR
$color-jaune: #ffcc00;
/*****************************************************/

/* GENERAL TEMPLATE */
body {
	color: #000000;
	font-size: 10px;
	font-weight: 400;
	margin-top: 100px;
	font-family: $font-1;
	line-height: 100%;
	a {
		text-decoration: none !important;
		&:hover, &:focus {
			text-decoration: none !important;
		}
	}
	a[href^=tel], a[href^=mailto] {

		&:hover {

		}
	}
	h1.referencement {
		display: none !important;
	}
	.appear {
		opacity: 0;
		@include transition(opacity 0.75s ease-in);
	}
	.parallax, .parallax-slow, .parallax-fast {
		background-attachment: fixed;
		@media screen and (max-width: 768px) {
			background-attachment: scroll;
		}
	}
}

/*****************************************************/
/*****************************************************/
.vissible-xs-only{
	display: none;
	@media screen and (max-width: 767px) {
		display: block;
	}
}
.no-padding {padding: 0;}

.btn-contact {
	background-color: #ec0e0e;
	color: #fff;
	display: block;
	font-size: 20px;
	font-weight: 400;
	margin: 0 auto;
	border: 0;
	padding: 10px 0;
	border-radius: 0px;
	text-align: center;
	line-height: 1;
	width: 300px;
	font-family: $font-1;
	&:hover {
		background-color: $color-jaune;
		color: #fff;
	}
	@media screen and (max-width: 767px) {
		width: 240px;
		padding: 10px 0;
	}
}
/***********mixins*************************************/
@mixin text-style ($size, $color, $family, $weight) {
	color: $color;
	font-size: $size;
	font-family: $family;
	font-weight: $weight;
}
@mixin top-style {
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	img {
		opacity: 0;
		min-width: 100%;
	}
	h3 {
		@include text-style(100px, #fff, $font-2 ,100);
		top: 50%;
		width: 100%;
		margin: 0;
		position: absolute;
		text-align: center;
		@include transform(translateY(-50%));
	}
	@media screen and (max-width: 767px) {
		h3 {
			font-size: 42px;
		}
	}
}
@mixin telephone-section-style {
	height: 200px;
	background-image: url('../images/body_section_01_strip_texture.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 60px 0;
	table {
		margin: 0 auto;
		text-align: center;
		td {
			border: 1px #fff solid;
			height: 80px;
			&:nth-child(1) {
				width: 80px;
				padding-left: 20px;
			}
			h3 {
				font-family: $font-1;
				font-size: 30px;
				font-weight: 300;
				color: #fff;
				padding: 25px 35px;
				margin: 0;
			}
		}
		.tel-icon{
			background-image: url('../images/body_section_01_phone_icon.png');
			background-repeat: no-repeat;
			background-size: cover;
			height: 32px;
			width: 32px;
		}
	}
	@media screen and (max-width: 768px){
		height: 100px;
		padding: 25px 0;
		table {
			td {
				height: 50px;
				&:nth-child(1) {
					width: 50px;
					padding-left: 10px;
				}
				h3 {
					font-size: 18px;
					padding: 15px 20px;
				}
			}
			.tel-icon{
				height: 25px;
				width: 25px;
			}
		}
	}
}
/*****************************************************/
.module-menu {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100px;
  z-index: 999;
	background-image: url('../images/header_strip_texture.jpg');
	background-size: auto 100%;
	@include transition(all 0.2s ease);
	border-bottom: 5px solid $color-jaune;
/* padding de logo*/
	.logo {
		float: left;
		padding: 6px;
		@include transition(all 0.2s ease);
	}
	//menu to right
	.main-menu {
		width: auto;
		height: 81%;
		.menu-toggle {
			margin-right: 18px;
			margin-top: 30px;
			float: right;
			display: none;
			cursor: pointer;
			padding: inherit;
			.bar {
				width: 50px;
				height: 2px;
				display: block;
				background: #fff;
				&:nth-child(2) {
					margin: 15px 0;
				 }
	  	}
			@media screen and (max-width: 1245px) {
				display: block;
			}
		}
		.menu-list {
			height: 80px;
			display: block;
			@media screen and (max-width: 1245px) {
				display: none;
			}
			.menu {
				margin: 0;
				padding-right: 43px;
				padding-left: 0;
				height: 70px;
				float: right;
				list-style: none;
				li {
					margin: 42px 0;
					float: left;
					position: relative;
					padding: 0px 15px;
					list-style: none;
					font-size: 18px;
					line-height: 15px;
					@include transition(all 0.2s ease);
					a {
						@include text-style(17px,#fff,$font-1,500);
						text-transform: uppercase;
						padding: 5px 0;
					}
					&.active {
						a {
							color: $color-jaune;
							border-bottom: 2px solid $color-jaune;
							border-top: 2px solid $color-jaune;
						}
					}
					&:hover {
						a {
							color: $color-jaune;
							border-bottom: 2px solid $color-jaune;
							border-top: 2px solid $color-jaune;
						}
					}
					&:nth-last-child(1){
						a {
							padding: 0 5px;
							font-weight: 700;
							color: $color-jaune;
							border-bottom: none;
							border-top: none;
							border-right: 1px solid $color-jaune;
							border-left: 1px solid $color-jaune;
						}
					}
					.facebook {
						height: 26px;
						width: 26px;
						margin: -6px 0;
						background-image: url('../images/header_fb_icon.png');
						&:hover{
							background-image: url('../images/header_fb_icon_hover.png');
						}
					}
					.linkedin {
						height: 26px;
						width: 26px;
						margin: -6px 0;
						background-image: url('../images/header_in_icon.png');
						&:hover{
							background-image: url('../images/header_in_icon_hover.png');
						}

					}
				}
			}
		}
	}

}
/************menu movile***************************/
#mobile-main-menu {
	top: -200%;
	left: 0;
	right: 0;
	z-index: 999;
	overflow: hidden;
	position: fixed;
	background: #000;
	@include transition(all 0.5s ease-in);
	//@include transform(translate(0%, -100%));
	@media screen and (max-width: 1245px) {
		&.toggled {
			overflow-y: auto;
			top: 0;
			@include transition(all 0.5s ease-in);
			//@include transform(translate(0%, 0%));
		}
	}
	.menu-toggle {
		top: 15px;
		right: 20px;
		cursor: pointer;
		position: absolute;
		.bar {
			width: 50px;
			height: 2px;
			display: block;
			background: #fff;
			&:nth-child(2) {
				margin: 15px 0;
			}
		}
	}
	ul {
		margin: 40px auto;
		padding: 30px;
		display: table;
		list-style: none;
		li {
			position: relative;
			text-align: center;
			font-size: 25px;
			font-weight: 400;
			line-height: 100%;
			padding: 5px;
			border-bottom: 1px solid $color-jaune;
			&:nth-last-child(1) {
				border-bottom: none;
			}
			a {	color: #fff;}
			.submenu {
				margin: 5px auto;
				li {
					border-bottom: none;
					font-size: 18px;
					a {
						color: #b9b9b9;
					}
				}
			}
			.facebook {
				height: 26px;
				width: 26px;
				margin: 0 auto;
				background-image: url('../images/header_fb_icon.png');
				&:hover{
					background-image: url('../images/header_fb_icon_hover.png');
				}
			}
			.linkedin {
				height: 26px;
				width: 26px;
				margin: 0 auto;
				background-image: url('../images/header_in_icon.png');
				&:hover{
					background-image: url('../images/header_in_icon_hover.png');
				}
			}
		}
	}
}
.slideshow-wrap {
		position: relative;
		.ls-video-layer {
				width: 100%!important;
				height: 100%!important;
		}
		#video-layer{height: 100%!important;}
		div.caption {
				position: absolute;
				bottom: 80px;
				right: 0;
				z-index: 3;

				background:rgba(0, 0, 0, .7);
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 30px 15px;
				h3 {
						color: #ffaa1d;
						font-weight: 900;
						font-size: 40px;
						line-height: 1.2;
						text-align: right;
						span {
								text-transform: uppercase;
						}
				}
				@media screen and (max-width: 767px){
						bottom: 0;
						//left: 0;
						h3 {
								font-size: 22px;
						}
						div.img{
								width: 100px;
						}
				}
		}
}
/*******************telephone section********************************************/
.telephone-section {@include telephone-section-style;}

.telephone-section {
	display: flex;
    justify-content: space-evenly;
    align-items: center;
    @media screen and (max-width: 1024px){
    	.img {
    		width: 40%;
    	}

    }
}

/*******************************************************************************/
.section01-index {
	// background-image: url('../images/body_section_01_bg.jpg');
	// background-position: center;
	// background-size: cover;
	// background-attachment: fixed;
	background-color: #ffcc00;
	padding: 30px 0;
	.img-container {
		img {margin: 0 auto;}
	}
	p {
		@include text-style(18px,#000,$font-2,700);
		line-height: 2.3;
		margin: 0;
		border-bottom: 1px solid #fff;
		padding: 20px 0 20px 5%;
		&:nth-last-child(1){
			border-bottom: 0;
		}
		.nom {color: #fff;}
	}
	@media screen and (max-width: 1200px) {
		p {font-size: 16px;}
	}
	@media screen and (max-width: 991px) {
		p {
			font-size: 14px;
			padding: 6px 0 6px 5%;
		}
	}
	@media screen and (max-width: 767px) {
		.img-container{padding-left: 0;}
		p {
			padding: 20px 10px;
			line-height: 3;
			font-size: 18px;
		}
	}
}
.section02-index {
	background-image: url('../images/burst_strip.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 240px;
	border-bottom: 5px solid $color-jaune;
	@media screen and (max-width: 767px) {
		background-image: url('../images/burst_parallax_strip_m.jpg');
	}
}

/***********************-*******************************************************/
/******************burst section************************************************/
/***********************-*******************************************************/
.burst-index {
	padding: 50px 0;
	background-image: url('../images/burst_bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	p {
		@include text-style(30px,#fff,$font-2,700);
		line-height: 2;
		margin: 0;
		text-align: center;
		padding-bottom: 50px;
		.contact {
			text-transform: uppercase;
			color: $color-jaune;
		}
	}

	.burst-left {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		background-color: #ac0401;
		padding: 20px 15px;
		height: 100%;
		@include transition(all 0.2s ease-in);
		div.icon-burst {
				max-width: 45%;
				position: relative;
				div.overlay {
						position: absolute;
						opacity: 0;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						visibility: hidden;
				}
		}
		div.txt {
			h3 {
				@include text-style(40px,#fff,$font-1,900);
				line-height: 1;
				margin: 0;
				// top: 20px;
				// right: 12%;
				text-align: right;
				//position: absolute;
			}
			p {
					color: #ffcc00;
					font-size: 25px;
					font-weight: 900;
					line-height: 1.3;
					padding-top: 25px;
					padding-bottom: 0;
					text-align: right;
					@include transition(all 0.2s ease-in);
					span {
						font-weight: 400;
					}
			}
		}
		.triangle {
			top: 20px;
			left: 20px;
			width: 0;
			height: 0;
			position: absolute;
			border-style: solid;
			border-width: 80px 80px 0 0;
			border-color: $color-jaune transparent transparent transparent;
			@include transition(all 0.2s ease-in);
		}
		&:hover {
			//background-image: url('../images/burst_01_bg_hover.jpg');
			@include transition(all 0.2s ease-in);
			background-color: $color-jaune;
			 .triangle {
				 	top: 10px;
					left: 10px;
					opacity: 0.9;
					@include transition(all 0.2s ease-in);
			}
			div.txt {
				h3, p {
					color: #fff;
					@include transition(all 0.2s ease-in);
				}
			}
			div.icon-burst {
				div.overlay {
						opacity: 1;
						visibility: visible;
						@include transition(all 0.2s ease-in);
				}
			}
		}

	}
	.burst-right {
		//background-image: url('../images/burst_02_bg.jpg');
		display: flex;
		align-items: flex-start;
		justify-content: center;
		background-color: #ac0401;
		padding: 20px 15px;
		height: 100%;
		@include transition(all 0.2s ease-in);
		div.icon-burst {
				max-width: 44%;
				position: relative;
				div.overlay {
						position: absolute;
						opacity: 0;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						visibility: hidden;
				}
		}
		div.txt {
			h3 {
				@include text-style(40px,#fff,$font-1,900);
				line-height: 1;
				margin: 0;
				// top: 20px;
				// right: 12%;
				//text-align: right;
				//position: absolute;
			}
			p {
					color: #ffcc00;
					font-size: 25px;
					font-weight: 900;
					line-height: 1.3;
					padding-top: 25px;
					//text-align: right;
					padding-bottom: 0;
					@include transition(all 0.2s ease-in);
					span {
						font-weight: 400;
					}
			}
		}
		.triangle {
			top: 15px;
			right: 30px;
			width: 0;
			height: 0;
			position: absolute;
			border-style: solid;
			border-width: 0 70px 70px 0;
			border-color: transparent $color-jaune transparent transparent;
			@include transition(all 0.2s ease-in);
		}
		&:hover {
			background-color: $color-jaune;
			//background-image: url('../images/burst_02_bg_hover.jpg');
			@include transition(all 0.2s ease-in);
			//  .triangle {
			// 	top: 10px;
			// 	right: 10px;
			// 	opacity: 0.9;
			// 	@include transition(all 0.2s ease-in);
			// }
			div.txt {
				h3, p {
					color: #fff;
					@include transition(all 0.2s ease-in);
				}
			}
			div.icon-burst {
				div.overlay {
						opacity: 1;
						visibility: visible;
						@include transition(all 0.2s ease-in);
				}
			}
		}

	}

		@media screen and (max-width: 1200px) {
				.burst-left,.burst-right {
						div.txt{
								h3 {font-size: 34px;}
						}
				}
		}
		@media screen and (max-width: 991px) {
				.burst-left,.burst-right {
						div.txt{
								h3 {font-size: 25px;}
						}
				}
		}
		@media screen and (max-width: 767px) {
				p {font-size: 25px;}
				.burst-left,.burst-right {
						margin: 10px 0;
						div.txt{
								h3 {font-size: 20px;}
						}
				}
		}
}
/********************************************************************/
.module-footer {
	height: 60px;
	background-color: #262523;
	font-family: $font-1;
	position: relative;
	.div {
		border-right: 1px solid #909090;
		margin-top: 20px;
	}
	.left {
		color: #909090;
    font-size: 14px;
		float: right;
		padding: 0 15px;
		height: 15px;
		margin-top: 5px;
	}
	.right {
		float: left;
		padding: 0px;
		height: 15px;
		margin: 15px 0 0 25px;
		a {
			color: #909090;
			font-size: 14px;
		}
		img {	vertical-align: text-bottom;}
	}
	.footer-padding {	padding: 0;}
	.surcharge {
		height: 50px;
		width: 50px;
		border: 1px solid #595857;
		border-radius: 5px;
		position: absolute;
		right: 10px;
		top: 5px;
	}
		@media screen and (max-width: 767px) {
			text-align: center;
			padding-top: 10px;
			height: 90px;
			.right {
				float: none;
				margin: 15px 0 0 0;
			}
			.left {
				padding: 0;
				float: none;
				border: 0;
			}
			.surcharge {
				top: 20px;
			}
		}

}
/************************************************************************************/
/**********historique page***********************************************************/
/************************************************************************************/
.top-historique {
	@include top-style;
	background-image: url('../images/historique_header_img.jpg');
	@media screen and (max-width: 767px) {
		background-image: url('../images/historique_header_img_m.jpg');
	}
}
.section01-historique {
	background-image: url('../images/historique_bg_img.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	padding: 60px 0;
	h3 {
		@include text-style(42px , #fff, $font-2, 700 );
		line-height: 1.2;
		text-align: center;
		text-shadow: 2px 2px 5px #000;
	}
	p {
		@include text-style(26px , #000, $font-2, 500 );
		line-height: 1.2;
		text-align: center;
		padding: 25px 0;
	}
	@media screen and (max-width: 767px) {
		h3 {
			font-size: 30px;
		}
	}
	//style du livre dans ce fichier
	@import 'bookstyle';
}
/****************************************************************************************/
/********services style******************************************************************/
/****************************************************************************************/
.body-service {
	background-image: url('../images/historique_bg_img.jpg');
	background-attachment: fixed;
	background-size: cover;
}
.top-service {
	@include top-style;
	background-image: url('../images/services_header_img.jpg');
	@media screen and (max-width: 767px) {
		background-image: url('../images/services_header_img_m.jpg');
	}
}
.section01-service {
	padding: 100px 0;
	.truck-container {
		position: relative;
		.truck-icon, .dossier-icon, .timbre-icon, .driver-icon {
			position: absolute;
			top: 50%;
			background-size: 90% 90%;
			background-repeat: no-repeat;
			background-position: center;
			@include transform(translateY(-50%));
			@include transition(all 0.2s ease-in);
			img {opacity: 0;}
			&:hover {
				background-size: 100% 100%;
				@include transition(all 0.2s ease-in);
			}
		}
		.truck-icon {background-image: url('../images/services_truck_icon.png');}
		.dossier-icon {background-image: url('../images/services_folder_icon.png');}
		.timbre-icon {background-image: url('../images/services_certificat_icon.png');}
		.driver-icon {background-image: url('../images/services_driver_icon.png');}
	}
	p {
		@include text-style(22px , #000, $font-2, 700 );
		line-height: 2;
		margin: 0;
		padding: 30px 0;
	}
	h3 {
		@include text-style(40px , #fff, $font-2, 700 );
		text-shadow: 2px 2px 5px #000;
	}
	ul {
		padding-left: 20px;
		li {
			@include text-style(22px , #000, $font-2, 700 );
			text-transform: uppercase;
			line-height: 1.7;
		}
	}
	.nom {color: #fff;}
	.ligne-pointillee {
		width: 100%;
		margin: 50px 0;
		border-bottom: 7px solid #fff;
		border-bottom-style: dashed;
	}
	@media screen and (max-width: 767px) {
		padding: 30px 0;
		.truck-container {
			.truck-icon, .dossier-icon, .timbre-icon, .driver-icon {
				position: relative;
				max-width: 350px;
				height: auto;
				margin: 0 auto;
				top: 0;
				@include transform(translateY(0%));
				@include transition(all 0.2s ease-in);
			}
		}
		p {font-size: 16px;}
		h3 {font-size: 22px;}
		ul {
			li {font-size: 18px;}
		}
	}
}
.section02-service {
	padding: 100px 0;
	background-image: url('../images/services_parallax_bg_01.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	h3 {
			@include text-style(43px , #fff, $font-2, 500 );
			line-height: 1.2;
			width: 100%;
			text-align: center;
	}
	@media screen and (max-width: 767px) {
		background-image: url('../images/services_parallax_bg_01_m.jpg');
		h3 {font-size: 26px;}
	}
}
.section03-service {
	background-image: url('../images/services_parallax_bg_02.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 30px 0;
	img {margin: 0 auto;}
	p {
		@include text-style(43px , #fff, $font-2, 500 );
		line-height: 1.2;
		width: 100%;
		text-align: center;
	}
	@media screen and (max-width: 767px) {
		p {font-size: 30px;}
	}
}
/***************************************************************************/
/**********commandes********************************************************/
/***************************************************************************/
.top-commandes {
	@include top-style;
	background-image: url('../images/commandes_header_img.jpg');
	@media screen and (max-width: 767px) {
		background-image: url('../images/commandes_header_img_m.jpg');
	}
}
.section01-commandes {
	padding: 30px 0;
	background: #ffcc00;
	.input-group {
		background: rgba(0, 0, 0, 0.40);
		padding: 10px 0;
		margin: 30px 0;
	}
	.no-padding-left {padding-left: 0;}
	.form-control {
		height: 50px;
		margin: 10px 0;
		font-size: 20px;
		background: #fff;
		color: #000;
		border-radius: 0;
	}
	.label-commande {
		font-size: 16px;
		color: #fff;
		padding-top: 20px;
		font-weight: 400;
	}
	.box {
		display: flex;
		padding: 5px 15px;
		.checkbox, .radio {
			height: 20px;
			width: 20px;
			background: #eeeeee;
			border-radius: 0;
			padding: 0;
			margin: 0;
			cursor: pointer;
		}
		.radio {border-radius: 50%;}
		input[type="checkbox"],input[type="radio"]{
			display: none;
			&:checked + label {
					background-image: url('../images/soumission_checkmark.png');
					background-position: center;
					background-size: 100% 100%;
			}
		}
		.label {
			color: #fff;
			font-size: 15px;
			font-weight: 400;
			line-height: 1;
			font-family: $font-2;
			margin: 2px 0;
		}
	}
	.btnAdd, .btnDel {
		font-size: 16px;
		color: #000;
		font-family: $font-2;
		font-weight: 700;
		text-transform: uppercase;
		background-color: rgba(240, 128, 128, 0);
		border: 0;
		&:hover{
			color: #ec0e0e;
		}
	}
	.btnDel{
		float: right;
		display: none;
	}
	@media screen and (max-width: 767px) {
		.btnDel{float: left;
		line-height: 2.5;}

	}
}
/*******************************************************************************/
/********emplois****************************************************************/
/*******************************************************************************/
.top-emplois {
		@include top-style;
		background-image: url('../images/emplois_header_img.jpg');
		@media screen and (max-width: 767px) {
				background-image: url('../images/emplois_header_img_m.jpg');
		}
		background-image: url('../images/equipements_header.jpg');
		@media screen and (max-width: 767px) {
				background-image: url('../images/equipements_header_m.jpg');
		}
}
.top-nos-equipements {
		@include top-style;
		h3 {
			font-size: 60px;
			font-family: $font-1;
		}
		background-image: url('../images/equipements_header.jpg');
		@media screen and (max-width: 767px) {
				background-image: url('../images/equipements_header_m.jpg');
		}
}
.section01-emplois {
	padding: 30px 0;
	background-image: url('../images/historique_bg_img.jpg');
	background-position: center;
	background-size: cover;
	text-align: center;
	p {
		@include text-style(22px , #000, $font-2, 700 );
		line-height: 2;
		margin: 0;
		padding: 30px 0;
	}
	h3 {
		@include text-style(40px , #fff, $font-2, 700 );
		text-shadow: 2px 2px 5px #000;
	}
	@media screen and (max-width: 767px) {
		h3 {font-size: 26px;}
		p {font-size: 18px;}
	}
}
.pb20 {
	padding-bottom: 20px!important;
}
.pt20 {
	padding-top: 20px!important;
}
.section01-nos-equipements {
		@extend .section01-emplois;
		//background-color: $color-jaune;
		//background-image: none;
		p {
			padding: 0;
			font-family: $font-2;
			line-height: 1.3;
			font-size: 22px;
			text-align: left;
		}
		h4 {
				@include text-style(30px , #000, $font-1, 700 );
				line-height: 2;
				margin: 0;
				font-style: italic;
				text-align: left;
				padding-bottom: 20px;
			}
}

.section02-nos-equipements {
		padding: 50px 0;
		background-color: #000;
		h3 {
			color: #fff;
			margin-top: 0;
		}
		p {
			@include text-style(18px , #fff, $font-1, 400 );
			padding: 20px 0;
			line-height: 1.5;
		}
		li {
				color: #fff;
				font-size: 14px;
				line-height: 1.3;
		}
		.row {
				border-bottom: 1px solid #fff;
    			padding: 50px 0;
		}

}
.section02-emplois {
	background: #262523;
	padding: 50px 0;
	.titre-container {
		display: flex;
		background: #51514f;
		padding: 15px;
		position: relative;
		h3 {
			@include text-style(24px , #fcca00, $font-2, 700 );
			margin: 0;
		}
		.pdf {
			background-image: url('../images/emplois_pdf_icon.png');
			background-size: 100% 100%;
			height: 38px;
			width: 38px;
			float: right;
			position: absolute;
			right: 10px;
			top: 10px;
			&:hover {
				background-image: url('../images/emplois_pdf_icon_hover.png');
			}
		}
	}
	p {
		@include text-style(18px , #fff, $font-2, 400 );
		padding: 40px 0;
		line-height: 2;
	}
}
/*******************************************************************************/
/*******************************************************************************/
/*******************************************************************************/
.top-contact {
	@include top-style;
	h3 {
			font-family: $font-1;
	}
	background-image: url('../images/coordonnees_header_img.jpg');
	@media screen and (max-width: 767px) {
		background-image: url('../images/coordonnees_header_img_m.jpg');
	}
}
.section01-contact {
	background: #ffcc00;
	padding: 30px 0;
	h4 {
		@include text-style(35px , #000, $font-1, 700 );
		line-height: 1.5;
	}
	h3 {
		@include text-style(50px , #fff, $font-1, 700 );
		margin: 0;
	}
	.telephone-liste {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			p {
					@include text-style(17px , #000, $font-1, 700 );
					padding: 0;
					margin: 0;
					line-height: 1.5;
					span {
						color: #fff;
					}
			}
	}
	@media screen and (max-width: 991px) {
		h3 {font-size: 30px;}
		h4 {font-size: 26px;}
		.telephone-liste {
			padding: 30px 10px 0 0;

			p {
				font-size: 12px;
				line-height: 2.5;
			}
		}
	}
}
.section02-contact {
	background: #000;
	padding: 30px 10%;
	h3 {
		@include text-style(35px , #fff, $font-2, 700 );
	}
	p {
		line-height: 2;
		border-bottom: 1px solid darken(#ffcc00, 40%); ;
		@include text-style(17px , #fff, $font-2, 700 );
		.nom {color: #ffcc00;	}
		}
		.tel {white-space: nowrap;}
		a {
			color: #fff;
			text-align: right;
			&:hover {
				color: #ffcc00;
			}
		}
		div.form {
			margin-top: 40px;
			.form-input {
				margin: 15px 0;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				label {
					color: #fff;
					font-size: 20px;
				}
				input {
					border-radius: 0;
				}
			}
		}
		@media screen and (max-width: 1470px) {
			padding: 30px 5%;
			@media screen and (max-width: 1335px) {
				p {
					font-size: 16px;
				}
				@media screen and (max-width: 1260px) {
					padding: 30px 2%;
					@media screen and (max-width: 1170px) {
						.col-md-8 {
						    width: 71%;
						}
						.col-md-2 {
						    width: 12%;
						}
						@media screen and (max-width: 1100px) {
							p {
							    font-size: 14px;
							}
							p .nom {
							    font-size: 17px;
							}
							.tel {
								   line-height: 2.43;
							}
							
								

								@media screen and (max-width: 991px) {
									p {
									    font-size: 17px;
									}
									@media screen and (max-width: 500px) {
										.col-md-8, .col-md-2 {
										    width: 100%;
										}
									}
								}
							
						}
					}
				}
			}
		}
	}
