.book_wrapper {
  margin: 0 auto;
  padding-top: 50px;
  width: 1010px;
  height: 660px;
  position: relative;
  background: transparent url('../images/book/bg.png') no-repeat 9px 27px;
  * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .cover {
    background-image: url('../images/book/cover.jpg');
    background-size: cover;
    background-position: center;
    height: 600px;
    width: 450px;
    margin: -9px;
  }
  h1 {
      color: #13386a;
      margin: 5px 5px 5px 15px;
      font-size: 26px;
      padding-bottom: 7px;
  }
  .booklet .b-wrap-right img {
      border: 1px solid #E6E3C2;
  }
  .booklet .b-wrap-left {
      padding-left: 20px!important;
  }
  img.imgleft {
      float: left;
      margin: 0 15px 15px 0;
  }
  img.imgright {
      float: right;
      margin: 0 0 15px 15px;
  }
  img {
    padding: 4px;
    border: 1px solid #ddd;
    -moz-box-shadow: 1px 1px 1px #fff;
    -webkit-box-shadow: 1px 1px 1px #fff;
    box-shadow: 1px 1px 1px #fff;
    max-width: 100%;
  }
  p {
      font-size: 13px;
      margin: 5px 5px 5px 5px;
      line-height: 20px;
      text-align: justify;
      padding: 0;
  }
  .front {
    padding-top: 100px;
    p {
      font-size: 20px;
      text-align: center;
      padding: 25px 40px;
      line-height: 1.5;
    }
  }
  .b-counter {
      bottom: 10px;
      position: absolute;
      display: block;
      width: 90%;
      height: 20px;
      border-top: 1px solid #ddd;
      color: #222;
      text-align: center;
      font-size: 11px;
      padding: 5px 0 0;
      background: transparent;
      -moz-box-shadow: 0px -1px 1px #fff;
      -webkit-box-shadow: 0px -1px 1px #fff;
      box-shadow: 0px -1px 1px #fff;
      opacity: 0.8;
  }
  a#next_page_button, a#prev_page_button {
      position: absolute;
      width: 41px;
      height: 40px;
      cursor: pointer;
      margin-top: -20px;
      top: 50%;
      background: transparent url('../images/book/buttons.png') no-repeat 0px -40px;
  }
  a#prev_page_button {
      left: -30px;
  }
  a#next_page_button {
      right: -30px;
      background-position: -41px -40px;
  }
  @media screen and (max-width: 1100px) {
    width: 750px;
    background-image: none;
    .cover {
      height: 600px;
      width: 350px;
      margin: -9px 2px 0 -9px;
    }
    p {
      font-size: 11px;
      line-height: 16px;;
    }
    a#next_page_button, a#prev_page_button {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    background: #fff;
    width: 98%;
    max-width: 400px;
    padding-top: 0;
    height: auto;
    .cover {
      height: 600px;
      width: 100%;
      margin: 0;
    }
    .owl-next{
      position: absolute;
      right: 0
    }
    .owl-prev{
      position: absolute;
      left: 0;
    }
    img {
      padding: 0px;
    }
    p {
        font-size: 13px;
        margin: 5px 5px 5px 5px;
        padding: 0;
        padding-right: 20px;
    }
  }
}
